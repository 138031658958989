<template>
  <div class="goods-rank">
    <div class="goods-rank-list" v-for="(item,index) in rankList" :key="index">
      <div class="rank-type">{{item.title}}</div>
      <div class="goods-rank-data">
        <div class="title">
          <div>排名</div>
          <div>商品</div>
          <div>{{item.alias}}</div>
        </div>
        <div class="item" v-for="(items,indexs) in item.list" :key="indexs">
          <div class="rank">{{indexs + 1}}</div>
          <div class="goods-info">
            <el-image :src="items.goods_picture"></el-image>
            <div>{{items.goods_name}}</div>
          </div>
          <div class="num">{{items.num}}</div>
        </div>
        <div class="empty" v-if="!item.list.length">暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['rankList'],
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style lang="less" scoped>
.goods-rank {
  display: flex;
  margin-top: 23px;
  .goods-rank-list {
    padding: 20px;
    width: 506px;
    margin-right: 25px;
    background: #f7f8fa;
    &:last-child {
      margin: 0;
    }
    .goods-rank-data {
      margin-top: 15px;
      .title {
        color: #666;
        display: flex;
        div:nth-child(1) {
          width: 80px;
        }
        div:nth-child(2) {
          flex: 1;
        }
        div:nth-child(3) {
          width: 100px;
          text-align: center;
        }
      }
      .item {
        display: flex;
        align-items: center;
        margin-top:21px;
        .rank {
          color: #999;
          width: 80px;
        }
        .goods-info {
          display: flex;
          align-items: center;
          flex: 1;
          .el-image {
            width: 64px;
            height: 63px;
            border-radius: 4px;
            margin-right: 12px;
          }
          div {
            width: 210px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .num {
          width: 100px;
          color: #999;
          text-align: center;
        }
      }
      .empty{
        color: #909399;
        text-align: center;
        margin-top:10px;
      }
    }
  }
}
</style>
